import React, { useEffect, useState } from "react";
import "./Contact.css";
import "aos/dist/aos.css"; // Assuming you're using AOS for animations
import AOS from "aos";
import bottomshape from "../../assets/images/bottomshape.png";
import signup from "../../assets/images/signup.png";
import { FaArrowRightLong } from "react-icons/fa6";
import Footer from "../../Comman/Footers/Footer";
import Navbar from "../../Comman/Header/Navbar";
import Clients from "../Home/Lifecycles/Clients";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import Rqform from "./Rqform";

const Request = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <>
      <Navbar />
      <section className="sign-up auth-container inner-hero-section overflow-hidden texture-bg-2 pt-120">
        {/* <div className="top-wave-shape mt-n1">
        <img className="w-100" src="assets/img/top-wave-shape-2.png" alt="wave shape" />
      </div>
      <div className="circle-shape shape-1 alt d-none d-md-block">
        <img className="w-100" src="assets/img/shape-1.png" alt="shape" />
      </div>
      <div className="circle-shape shape-2 alt d-none d-md-block">
        <img className="w-100" src="assets/img/shape-2.png" alt="shape" />
      </div>
      <div className="circle-shape shape-3 alt d-none d-md-block">
        <img className="w-100" src="assets/img/shape-3.png" alt="shape" />
      </div> */}
        <div className="container pt-120 ">
          <div className="techlogis pb-120">
            <div className="request-heading mb-5 ">
              <h5 data-aos="fade-up">Request a quote to get started on your project with us!</h5>
              <p data-aos="fade-up">
                We are eager to collaborate with you. Please share some details
                about yourself and your project, and we'll provide a quote and
                time estimate.
              </p>
            </div>
            <div className="timeline d-lg-flex d-grid align-items-baseline gap-6">
              <div className="timeline-item text-center text-lg-start" data-aos="fade-up">
                <div
                  className="timeline-bar d-flex align-items-center justify-content-center
                     justify-content-lg-start gap-2 mb-lg-6 mb-2"
                >
                  <div className="timeline-dot fs-four">
                    <span>1</span>
                  </div>
                  <div className="timeline-border d-none d-lg-block"></div>
                </div>
                <div className="timeline-content">
                  <h4 className="timeline-title fw-medium mb-3">
                  Proposal Preparation:
                  </h4>
                  <p className="fs-sm fw-normal">
                    We will create a proposal that outlines the scope, timeline,
                    and estimated pricing based on the detailed information you
                    provide about your project.
                  </p>
                </div>
              </div>

              <div className="timeline-border d-lg-none"></div>
              <div className="timeline-item text-center text-lg-start" data-aos="fade-up">
                <div
                  className="timeline-bar d-flex align-items-center justify-content-center
                     justify-content-lg-start gap-2 mb-lg-6 mb-2"
                >
                  <div className="timeline-dot fs-four">
                    <span>2</span>
                  </div>
                  <div className="timeline-border d-none d-lg-block"></div>
                </div>
                <div className="timeline-content">
                  <h4 className="timeline-title fw-medium mb-3">
                    Collaborative Discussion:
                  </h4>
                  <p className="fs-sm fw-normal">
                    Let’s connect to explore all potential options and
                    solutions. A requirements gathering meeting is the best way
                    to ensure alignment.
                  </p>
                </div>
              </div>

              <div className="timeline-border d-lg-none"></div>
              <div className="timeline-item text-center text-lg-start"data-aos="fade-up">
                <div
                  className="timeline-bar d-flex align-items-center justify-content-center 
                    justify-content-lg-start gap-2 mb-lg-6 mb-2"
                >
                  <div className="timeline-dot fs-four">
                    <span>3</span>
                  </div>
                  <div className="timeline-border d-none d-lg-block"></div>
                </div>
                <div className="timeline-content">
                  <h4 className="timeline-title fw-medium mb-3">
                    Project Initiation:
                  </h4>
                  <p className="fs-sm fw-normal">
                    After the contract is signed and all objectives are set, we
                    will commence the first sprint.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-6 justify-content-between">
            <div className="col-lg-6 col-md-12">
              <div className="sign-up-form" data-aos="fade-up">
              <Rqform/>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="auth-banner" data-aos="fade-down">
                <img className="w-100" src={signup} alt="sign up" />
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-wave-shape">
          <img className="w-100" src={bottomshape} alt="wave shape" />
        </div>
      </section>
      <Clients />
      <Footer />
    </>
  );
};

export default Request;
