import React from "react";
import "aos/dist/aos.css"; 
import { useEffect } from "react";
import AOS from "aos";
import "./Lifecycle.css";
import { BsRocketTakeoff } from "react-icons/bs";
import whychoose from "../../../assets/images/whychoose.png";
import arrowvector from "../../../assets/images/arrowvector.png";
import elevate2 from "../../../assets/images/elevate2.png";
import vector2 from "../../../assets/images/vector2.png";
import CountUp from 'react-countup';
import { FaHandsHoldingCircle } from "react-icons/fa6";
import { MdOutlineSettings } from "react-icons/md";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { IoIosGlobe } from "react-icons/io";
import { Container,Row,Col } from 'react-bootstrap';


const Whychooseus = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <section className="elevate-your-experience pt-0 pb-0 texture-bg-2">
      <Container>
        <Row className="g-6 align-items-center justify-content-between">
          <Col lg={7} data-aos="fade-up">
            <div className="text-center text-lg-start">
              <span className="subheading-border fw-medium mb-4 fs-xl">
                <span className="fs-2xl">
                  <i><BsRocketTakeoff/></i>
                </span>
                Why Choose Us
              </span>
              <h4 className="display-four mb-lg-6 mb-4">
                What Makes us <span className="tcp-1">Different</span>
              </h4>
              <p>
                At IMPETROSYS, we set ourselves apart through our commitment to
                delivering tailored solutions that address your specific needs.
                Our innovative approach leverages the latest technology to keep
                your business ahead of the curve. We provide dedicated support,
                ensuring you receive personalized assistance at every stage. Our
                proven results, backed by client testimonials and case studies,
                showcase our success in driving tangible outcomes for our
                partners.
              </p>
            </div>

            <div className="d-flex align-items-center flex-column flex-sm-row gap-xxl-15 gap-sm-6 gap-4 my-lg-10 my-sm-6 my-4">
              <div className="d-flex gap-4">
                <span className="icon-area tcp-1 bl-1 ps-2 fs-one fw-normal">
                <i><FaHandsHoldingCircle/></i>
                </span>
                <div className="d-grid gap-3">
                  <span className="fs-four fw-medium">Tailored Solutions</span>
                  <span className="fw-normal">
                    Designed to meet specific needs and deliver optimal results.
                  </span>
                </div>
              </div>
              <div className="d-flex gap-4">
                <span className="icon-area tcp-1 bl-1 ps-2 fs-one fw-normal">
                  <i><MdOutlineSettings/></i>
                </span>
                <div className="d-grid gap-3">
                  <span className="fs-four fw-medium">Cost-Effective</span>
                  <span className="fw-normal">
                    Efficient development solutions that minimizing costs.
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center flex-column flex-sm-row gap-xxl-15 gap-sm-6 gap-4 my-lg-10 my-sm-6 my-4">
              <div className="d-flex gap-4">
                <span className="icon-area tcp-1 bl-1 ps-2 fs-one fw-normal">
                  <i><HiOutlineUserGroup/></i>
                </span>
                <div className="d-grid gap-3">
                  <span className="fs-four fw-medium">Customer-Centric</span>
                  <span className="fw-normal">
                    Prioritizes customer needs while delivering exceptional
                    experiences.
                  </span>
                </div>
              </div>
              <div className="d-flex gap-4">
                <span className="icon-area tcp-1 bl-1 ps-2 fs-one fw-normal">
                <i><IoIosGlobe/></i>
                </span>
                <div className="d-grid gap-3">
                  <span className="fs-four fw-medium">Global Expertise</span>
                  <span className="fw-normal">
                    Specialized expertise drawn from diverse global markets.
                  </span>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={5}>
            <div className="elevate-experience-banner d-flex position-relative mt-10 mt-lg-0">
              <div className="banner-area-1">
                <div className="banner-1 ms-6 ms-sm-0">
                  <img
                    className="w-100 rounded"
                    src={whychoose}
                    alt="Elevate experience 1"
                  />
                </div>
                <div className="banner-shape-1">
                  <img
                    className="w-100"
                    src={arrowvector}
                    alt="Arrow shape"
                  />
                </div>
              </div>
              <div className="banner-area-2">
                <div className="banner-2">
                  <img
                    className="w-100 rounded"
                    src={elevate2}
                    alt="Elevate experience 2"
                  />
                </div>
                <div className="banner-shape-2">
                  <img
                    className="w-100"
                    src={vector2}
                    alt="Arrow shape 2"
                  />
                </div>
              </div>

              <div className="year-of-experience bcp-4 rounded">
                <div className="d-flex gap-4">
                  <div className="odometer-item d-center tcn-900">
                    {/* <span
                      className="base display-four text-nowrap odometer odometer-auto-theme"
                      data-odometer-final="10"
                    >
                      <span>10</span>
                    </span> */}
                    <CountUp start={1} end={10} delay={0}>
                    {/* {({ countUpRef }) => (
                        <div>
                        <span ref={countUpRef} />
                        </div>
                    )} */}
                    </CountUp>
                    <span className="display-four text-dark">+</span>
                  </div>
                  <p className="fs-lg fw-medium tcn-900">
                    Years of experience
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Whychooseus;
