import React from "react";
import {
  FaHeadSideVirus,
  FaCodepen,
  FaHornbill,
  FaMobileAlt,
  FaLaptop,
  FaSith,
} from "react-icons/fa";
import { FaAws } from "react-icons/fa";
import { TbBrandMysql } from "react-icons/tb";
import { FaLaravel } from "react-icons/fa";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AOS from "aos";
import "./Technology.css";
import { ImHtmlFive2 } from "react-icons/im";
import { FaCss3Alt } from "react-icons/fa6";
import { Container, Row, Col } from "react-bootstrap";
import { BsBootstrap } from "react-icons/bs";
import { FaPhp } from "react-icons/fa6";
import { FaReact } from "react-icons/fa";
import { SiNodedotjs } from "react-icons/si";
import { DiMongodb } from "react-icons/di";
import { RiJavaLine } from "react-icons/ri";
import { BiLogoAndroid } from "react-icons/bi";
import { FaApple } from "react-icons/fa";
import { RiFlutterFill } from "react-icons/ri";
import { SiDailydotdev } from "react-icons/si";
import shape from "../../assets/images/shape.png";
import { Tabs, Tab } from 'react-bootstrap';
import { FaFigma } from "react-icons/fa";
import { SiCanva } from "react-icons/si";
import { FaAd } from "react-icons/fa";
import { DiPhotoshop } from "react-icons/di";
import { IoLogoJavascript } from "react-icons/io";
import { DiJqueryLogo } from "react-icons/di";
import { DiCodeigniter } from "react-icons/di";
import { FaPython } from "react-icons/fa";

const Technology = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <section className="technology  pb-120 texture-bg-2">
        <div className="top-wave-shape mt-n1">
          <img src={shape} className="w-100" alt="shape" />
        </div>
        <Container>
          <Row className="g-6 align-items-center justify-content-between pt-120">
            <Col md={12}>
              <div className="technology-t"data-aos="fade-down">
                <h4 className="display-four fw-bold text-center mb-4">
                  Technologies
                </h4>
                <h5>Technologies we work with</h5>
                <p>
                  We leveraged the strength of robust and secure technologies,
                  tools, and frameworks used in the app/web to escalate the
                  creation of new features and functionalities.
                </p>
              </div>
            </Col>
            <Tabs defaultActiveKey="Graphicdesign" id="uncontrolled-tab-example" className="mb-3">
        {/* Home Tab */}
        <Tab eventKey="Graphicdesign" title="Graphic design">
          <div className="p-3">
            <Row>
          <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "rgb(249 79 79);" }}>
                  <FaFigma />
                </i>
                <h5>Figma</h5>
              </div>
            </Col>
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "rgb(0 142 223)" }}>
                  <DiPhotoshop />
                </i>
                <h5>Photoshop</h5>
              </div>
            </Col>
            <Col lg={3} md={3} sm={4} xs={6}  data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "#FFB430" }}>
                  <SiCanva />
                </i>
                <h5>canva</h5>
              </div>
            </Col>
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "pink" }}>
                  <FaAd />
                </i>
                <h5>Adobe Illustrator</h5>
              </div>
            </Col>
            </Row>
          </div>
          
        </Tab>

        {/* Profile Tab */}
        <Tab eventKey="Front" title="Front End">
          <div className="p-3">
           <Row>
           <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "#ff7600", fontSize: "32px" }}>
                  <ImHtmlFive2 />
                </i>
                <h5>Html</h5>
              </div>
            </Col>
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "#556bd3" }}>
                  <FaCss3Alt />
                </i>
                <h5>Css</h5>
              </div>
            </Col>
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "#a521b5", fontSize: "32px" }}>
                  <BsBootstrap />
                </i>
                <h5>Bootsrap</h5>
              </div>
            </Col>
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "#0dcaf0" }}>
                  <FaReact />
                </i>
                <h5>React js</h5>
              </div>
            </Col>
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "#ffb430" }}>
                  <IoLogoJavascript />
                </i>
                <h5>JavaScript</h5>
              </div>
            </Col>
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "#0dcaf0" }}>
                  < DiJqueryLogo />
                </i>
                <h5>Jquery</h5>
              </div>
            </Col>
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "#f5d986" }}>
                  < FaPython />
                </i>
                <h5>Python</h5>
              </div>
            </Col>
           
           </Row>
          </div>
        </Tab>

        {/* Contact Tab */}
        <Tab eventKey="backend" title="Backend">
          <div className="p-3">
            <Row>
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "#658fdd", fontSize: "48px",marginBottom:"0px" }}>
                  <FaPhp />
                </i>
                <h5>Php</h5>
              </div>
            </Col>

           
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "green" }}>
                  <SiNodedotjs />
                </i>
                <h5>Node js</h5>
              </div>
            </Col>
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "red" }}>
                  <DiCodeigniter />
                </i>
                <h5>codeigniter</h5>
              </div>
            </Col>
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "#fff" }}>
                  <FaLaravel />
                </i>
                <h5>laravel</h5>
              </div>
            </Col>
            </Row>
          </div>
        </Tab>
        <Tab eventKey="database" title="Database">
          <div className="p-3">
            <Row>
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "#198754" }}>
                  <DiMongodb />
                </i>
                <h5>Mongo Db</h5>
              </div>
            </Col>
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "#45cfdd" }}>
                  <TbBrandMysql />
                </i>
                <h5>my sql</h5>
              </div>
            </Col>
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "rgb(218 231 27);" }}>
                  <FaAws />
                </i>
                <h5>AWS</h5>
              </div>
            </Col>
            </Row>
            
          </div>
        </Tab>
        <Tab eventKey="Mobile" title="Mobile">
          <div className="p-3">
            <Row>
        
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "#5bed00" }}>
                  <BiLogoAndroid />
                </i>
                <h5>Android</h5>
              </div>
            </Col>
            {/* <Col lg={2} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "#6c757d" }}>
                  <SiDailydotdev />
                </i>
                <h5>Dev Ops</h5>
              </div>
            </Col> */}

            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "#fff" }}>
                  <FaApple />
                </i>
                <h5>Ios</h5>
              </div>
            </Col>
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "rgb(0 142 223)" }}>
                  <RiFlutterFill />
                </i>
                <h5>Flutter</h5>
              </div>
            </Col>
            <Col lg={3} md={3} sm={4} xs={6} data-aos="fade-up">
              <div className="leveragedSec_cnt">
                <i style={{ color: "#0dcaf0" }}>
                  <FaReact />
                </i>
                <h5>React Native</h5>
              </div>
            </Col>
            </Row>
          </div>
          
        </Tab>
      </Tabs>
           
            
        
           
          
          </Row>
        </Container>
      </section>
      <section className="main-technology pb-120">
        <Container>
          <Row>
            <Col md={12}>
              <h6 className="display-six text-center"data-aos="fade-down">
                Leveraging Technology to Deliver World-Class Solutions
              </h6>
              <div className="techlogis"data-aos="fade-up"data-aos-duration="2000">
                <div className="timeline d-lg-flex d-grid align-items-baseline gap-6">
                  <div className="timeline-item text-center text-lg-start">
                    <div
                      className="timeline-bar d-flex align-items-center justify-content-center
                     justify-content-lg-start gap-2 mb-lg-6 mb-2"
                    >
                      <div className="timeline-dot fs-four">
                        <span>
                          <FaCodepen />
                        </span>
                      </div>
                      <div className="timeline-border d-none d-lg-block"></div>
                    </div>
                    <div className="timeline-content">
                      <h4 className="timeline-title fw-medium mb-3">
                        Blockchain Technology
                      </h4>
                      <p className="fs-sm fw-normal">
                        Blockchain is a shared, immutable ledger that
                        facilitates the recording of transactions and tracking
                        of assets in a business network.
                      </p>
                    </div>
                  </div>

                  <div className="timeline-border d-lg-none"></div>
                  <div className="timeline-item text-center text-lg-start">
                    <div
                      className="timeline-bar d-flex align-items-center justify-content-center
                     justify-content-lg-start gap-2 mb-lg-6 mb-2"
                    >
                      <div className="timeline-dot fs-four">
                        <span>
                          <FaHornbill />
                        </span>
                      </div>
                      <div className="timeline-border d-none d-lg-block"></div>
                    </div>
                    <div className="timeline-content">
                      <h4 className="timeline-title fw-medium mb-3">
                        loT and ML
                      </h4>
                      <p className="fs-sm fw-normal">
                        IoT and machine learning deliver insights otherwise
                        hidden in data, enabling rapid, automated responses and
                        improved decision-making.
                      </p>
                    </div>
                  </div>

                  <div className="timeline-border d-lg-none"></div>
                  <div className="timeline-item text-center text-lg-start">
                    <div
                      className="timeline-bar d-flex align-items-center justify-content-center 
                    justify-content-lg-start gap-2 mb-lg-6 mb-2"
                    >
                      <div className="timeline-dot fs-four">
                        <span>
                          <FaHeadSideVirus />
                        </span>
                      </div>
                      <div className="timeline-border d-none d-lg-block"></div>
                    </div>
                    <div className="timeline-content">
                      <h4 className="timeline-title fw-medium mb-3">
                        Artificial Intelligence
                      </h4>
                      <p className="fs-sm fw-normal">
                        Artificial intelligence is the simulation of human
                        intelligence processes by machines, especially computer
                        systems.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="techlogis mt-4"data-aos="fade-up"data-aos-duration="2200">
                <div className="timeline d-lg-flex d-grid align-items-baseline gap-6">
                  <div className="timeline-border d-lg-none"></div>
                  <div className="timeline-item text-center text-lg-start">
                    <div
                      className="timeline-bar d-flex align-items-center justify-content-center 
                    justify-content-lg-start gap-2 mb-lg-6 mb-2"
                    >
                      <div className="timeline-dot fs-four">
                        <span>
                          <FaLaptop />
                        </span>
                      </div>
                      <div className="timeline-border d-none d-lg-block"></div>
                    </div>
                    <div className="timeline-content">
                      <h4 className="timeline-title fw-medium mb-3">
                        Web Technologies
                      </h4>
                      <p className="fs-sm fw-normal">
                        We work with the latest tech stacks like MEAN, MERN, and
                        Full Stack, including combinations of Angular, React.js,
                        and Node.js. We also work with PHP frameworks.
                      </p>
                    </div>
                  </div>

                  <div className="timeline-border d-lg-none"></div>
                  <div className="timeline-item text-center text-lg-start">
                    <div
                      className="timeline-bar d-flex align-items-center justify-content-center 
                     justify-content-lg-start gap-2 mb-lg-6 mb-2"
                    >
                      <div className="timeline-dot fs-four">
                        <span>
                          <FaSith />
                        </span>
                      </div>
                      <div className="timeline-border d-none d-lg-block"></div>
                    </div>
                    <div className="timeline-content">
                      <h4 className="timeline-title fw-medium mb-3">
                        Ul/UX Tools
                      </h4>
                      <p className="fs-sm fw-normal">
                        Our strong tools for UI/UX are Photoshop, Illustrator,
                        and CorelDRAW. We also use online prototyping tools like
                        Figma and InVision.
                      </p>
                    </div>
                  </div>
                  <div className="timeline-item text-center text-lg-start">
                    <div
                      className="timeline-bar d-flex align-items-center justify-content-center
                     justify-content-lg-start gap-2 mb-lg-6 mb-2"
                    >
                      <div className="timeline-dot fs-four">
                        <span>
                          <FaMobileAlt />
                        </span>
                      </div>
                      <div className="timeline-border d-none d-lg-block"></div>
                    </div>
                    <div className="timeline-content">
                      <h4 className="timeline-title fw-medium mb-3">
                        Mobile Technology
                      </h4>
                      <p className="fs-sm fw-normal">
                        We use native and hybrid technologies like Android Java,
                        Kotlin, Swift, Flutter, and React Native.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Technology;
