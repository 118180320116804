import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import "./Blog.css";
import { FaArrowRightLong } from "react-icons/fa6";
import { CiLocationArrow1 } from "react-icons/ci";
import { Container, Row, Col } from "react-bootstrap";
import FB from "../../assets/images/FB.png";
import twitter from "../../assets/images/twitter.png";
import linkedIn from "../../assets/images/linkedIn.png";
import insta from "../../assets/images/insta.png";
import google from "../../assets/images/google.png";
import { CiSearch } from "react-icons/ci";
import { IoIosStar } from "react-icons/io";
import { IoIosStarHalf } from "react-icons/io";
import { Link } from "react-router-dom";
import career from "../../assets/images/career.png";
import banner1 from "../../assets/images/banner1.png";
import banner3 from "../../assets/images/banner3.png";
import Navbar from "../../Comman/Header/Navbar";
import Footer from "../../Comman/Footers/Footer";
import img1 from "../../assets/images/blog-author.png";
import img2 from "../../assets/images/user-2.png";
import { SlLike } from "react-icons/sl";
import { MdOutlineComment } from "react-icons/md";
import  blog1 from "../../assets/images/blog1.png"
import  blog2 from "../../assets/images/blog2.png"
import  blog3 from "../../assets/images/blog3.png"
import  blog4 from "../../assets/images/blog4.png"
import  blog5 from "../../assets/images/blog5.png"





const Blogdetails = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <Navbar />
      <section className="hero-section inner-hero-section texture-bg pt-120 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12" data-aos="fade-up">
              <div className="hero-content text-center">
                <h4 className="display-four mb-2 pt-120">
                  Blog <span className="tcp-1">Details</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blog-details-section pb-120 pt-120 texture-bg-2 overflow-visible">
        <div className="container">
          <div className="row g-6">
            <div className="col-xl-8">
              <div className="blog-details-content-wrapper d-grid gap-6 p-xxl-6 p-lg-4 p-3 rounded bgc-3">
                <div
                  className="blog-details-card p-xxl-8 p-lg-6 p-3 rounded bgc-2"
                  data-aos="fade-up"
                >
                  <div className="img-area  mb-5" data-aos="zoom-in">
                    <img className="w-100 rounded" src={banner1} alt="image" />
                  </div>
                  <div className="blog-details-card-content">
                    <div className="btns-area d-flex flex-wrap align-items-center gap-lg-6 gap-4 mb-5">
                      <a href="#" className="bttn-2 green">
                        Impetrosys
                      </a>
                      <span className="tag-btn-2">9 Oct, 2024</span>
                      <div className="post-author d-flex align-items-center gap-4">
                        <div className="img-area">
                          <img
                            className="w-100 rounded-circle"
                            src={img1}
                            alt="image"
                          />
                        </div>
                        <span>Atharav Rajpoot</span>
                      </div>
                    </div>
                    <h2 className="fw-semibold mb-lg-5 mb-3">
                      ERP Software for HR Management
                    </h2>
                    <p className="tcn-20 fw-normal">
                      Human resource administration and management in any
                      industry is never a simple task but when it comes to
                      construction it becomes more complex and difficult to
                      manage, with different sets of challenges in the highly
                      mobile and unstable nature of work. Seamlessly integrating
                      with CONCORD payroll, this solution is designed to cope
                      with the ever-changing market landscape. It frees up time
                      by streamlining processes and providing transparency, with
                      the ultimate aim of increasing and improving employee
                      satisfaction.
                    </p>
                  </div>
                </div>

                <div className="blog-details-content d-grid p-xxl-8 p-lg-6 p-4 gap-xxl-8 gap-lg-6 gap-4 rounded bgc-2">
                  <div data-aos="fade-down">
                    <h4 className="fw-semibold mb-4">Benefits at a Glance:</h4>
                    <p className="tcn-20 fw-normal mb-lg-8 mb-sm-6 mb-4">
                      The Human Resources partner product brings together all
                      the employee records into a single location of effective
                      employee management, analysis, and reporting. This is
                      comprehensive human resources administration and
                      management solution designed specifically for the needs of
                      large, medium, and small contractors.{" "}
                    </p>
                    <ol className="tcn-20 fw-normal ps-5 list-number">
                      <li>Fully Integrated with CONCORD Payroll.</li>
                      <li>
                        A web-based system, accessible from anywhere, anytime
                      </li>
                      <li>
                        Electronic Approval System (EAS) and Employee Self-
                        Service (ESS) facilities help reduce duplication of
                        entries and save a substantial amount of time
                      </li>
                      <li>
                        Improve talent acquisition and retention throughout your
                        organization
                      </li>
                    </ol>
                  </div>

                  <div data-aos="fade-up">
                    <h4 className="fw-semibold mb-4">Key Features:</h4>

                    <ul className="tcn-20 fw-normal ps-5 list-disc">
                      <li>Enterprise-wide and region-specific</li>
                      <li>Mass capture facility</li>
                      <li>Camp Management</li>
                      <li>Passport, work permits, and Visa management</li>
                      <li>
                        Employee engagement (recruitment, employment, training,
                        appraisal, leave, and termination)
                      </li>
                      <li>Comprehensive job descriptions</li>
                      <li>
                        Disciplinary, misconduct, and grievance history
                        including warnings, hearings, outcomes and follow-ups
                        etc.
                      </li>
                    </ul>
                  </div>

                  <div
                    className="d-between flex-wrap gap-4 pt-lg-8 pt-sm-6 pt-4 border-dashed"
                    data-aos="fade-down"
                  >
                    <div className="follow-us d-between gap-4">
                      <div className="d-flex align-items-center tcp-1 gap-1 d-none d-xxl-block">
                        <span className="fw-semibold">Follow</span>
                        <span className="lh-0">
                          <i className="ti ti-arrow-right"></i>
                        </span>
                      </div>
                      <ul className="social-link d-flex align-items-center gap-3 fs-xl">
                        <li>
                          <a
                            href="https://www.facebook.com/ImpetrosysSoftwareSolution"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={FB}
                              alt="facebook icon"
                              className="footer-img"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://x.com/IMPETROSYS_IND"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={twitter}
                              alt="twitter icon"
                              className="footer-img"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/impetrosys"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={linkedIn}
                              alt="linkedin icon"
                              className="footer-img"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/_impetrosys"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={insta}
                              alt="instagram icon"
                              className="footer-img"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://g.co/kgs/89gVGro"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={google}
                              alt="google icon"
                              className="footer-img"
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="blog-details-comments-wrapper d-grid gap-lg-8 gap-6 p-xxl-8 p-4 mt-6 rounded bgc-3">
                <div
                  className="comments-header d-flex flex-wrap gap-3 align-items-center justify-content-between"
                  data-aos="fade-up"
                >
                  <h4 className="fw-semibold">All Comment</h4>
                </div>
                <div className="comments-area">
                  <div
                    className="comment-card d-grid gap-6 p-xxl-8 p-sm-6 p-4 rounded bgc-2"
                    data-aos="fade-down"
                  >
                    <div className="comment-time-area d-flex align-items-center gap-lg-6 gap-4 gap-2">
                      <span className="comment-date">Mar 03, 2024</span>
                      <span className="tcp-1">
                        <i className="ti ti-point-filled"></i>
                      </span>
                      <span className="comment-time">09:01 am</span>
                    </div>
                    <span className="d-block border-dashed"></span>
                    <div className="comment-text-area">
                      <ul className="d-flex align-items-center gap-1 tcp-1 mb-lg-4 mb-2">
                        <li>
                          <i>
                            <IoIosStar />
                          </i>
                        </li>
                        <i>
                          <IoIosStar />
                        </i>
                        <i>
                          <IoIosStar />
                        </i>
                        <i>
                          <IoIosStar />
                        </i>
                        <i>
                          <IoIosStarHalf />
                        </i>
                      </ul>
                      <p className="mb-lg-6 mb-sm-4 mb-2">
                        "I can't thank enough for the incredible courses they
                        offer. I completed the 'Web Development Fundamentals'
                        course, and it not only gave me the skills.”
                      </p>
                      <div className="commenter-info d-flex align-items-center gap-4">
                        <div className="img-area">
                          <img
                            className="w-100 rounded-circle"
                            src="assets/img/user-1.png"
                            alt="host"
                          />
                        </div>
                        <div className="">
                          <h5 className="fw-semibold mb-sm-2 mb-1">
                            Ashadul Islam
                          </h5>
                          <span className="fw-normal">Software Developer</span>
                        </div>
                      </div>
                    </div>
                    <span className="d-block border-dashed"></span>
                    <div className="like-comment">
                      <div className="like-comment-btns d-flex align-items-center gap-lg-10 gap-sm-6 gap-4">
                        <div className="like-btn-area fs-xl d-flex align-items-center gap-2">
                          <button className="like-btn">
                            <i>
                              <SlLike />
                            </i>
                          </button>
                          <span className="like-count">152</span>
                        </div>
                        <div className="comment-btn-area fs-xl d-flex align-items-center gap-2 cursor-pointer">
                          <button className="comment-btn">
                            <i>
                              <MdOutlineComment />
                            </i>
                          </button>
                          <span className="comment-text">Replay</span>
                        </div>
                      </div>
                      <div className="comment-sending-box">
                        <div className="d-flex align-items-center gap-sm-4 gap-2 mt-3">
                          <div className="img-area">
                            <img
                              className="w-100 rounded-circle"
                              src={img2}
                              alt="host"
                            />
                          </div>
                          <form action="#" className="w-100">
                            <div className="input-area">
                              <input
                                type="text"
                                placeholder="Join the 
                                 discussion..."
                                className="form-control"
                              />
                              <button type="submit" className="icon-btn p-0">
                                <span className="icon alt-size fs-xl fw-normal">
                                  <i>
                                    <CiLocationArrow1 />
                                  </i>
                                </span>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="see-all-comments">
                  <a href="#" className="bttn-1 bttn-outline">
                    See all
                    <span className=" icon d-center icon-right">
                      <i>
                        <FaArrowRightLong />
                      </i>
                    </span>
                  </a>
                </div>
              </div>

              <div className="new-comment-from  mt-4 " data-aos="fade-up">
                <div className="d-grid gap-lg-6 gap-4 ">
                  <h4 className="fw-semibold">Write a Comments</h4>
                  <span className="d-block border-dashed"></span>
                  <form action="#" className="d-grid gap-6">
                    <div className="input-wrapper alt-color-2 d-grid gap-3 w-100">
                      <label for="name">Name </label>
                      <input
                        type="text"
                        id="name"
                        placeholder="Enter your name"
                        className="form-control"
                      />
                    </div>
                    <div className="input-wrapper alt-color-2 d-grid gap-3 w-100">
                      <label for="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        placeholder="Enter your email"
                        className="form-control"
                      />
                    </div>
                    <div className="input-wrapper alt-color-2 d-grid gap-3 w-100">
                      <label for="comment">Write a Comments</label>
                      <textarea
                        name="comment"
                        id="comment"
                        rows="4"
                        placeholder="Enter your comment"
                        className="form-control"
                      ></textarea>
                    </div>

                    <div className="mt-lg-4">
                      <button className="bttn-1 bttn-outline">
                        Submit Comment
                        <span className=" icon d-center icon-right">
                          <i>
                            <FaArrowRightLong />
                          </i>
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div
                className="blog-details-sidebar d-grid  gap-4 p-4 
              rounded bgc-3 "
              >
                <div
                  className="search-card-wrapper p-xxl-8 p-lg-6 p-4 rounded bgc-2"
                  data-aos="zoom-in"
                >
                  <h4 className="fw-semibold mb-4">Search</h4>
                  <form action="#" className="border-dashed pt-4">
                    <div className="input-area ">
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control"
                      />
                      <button type="submit" className="icon-btn p-0">
                        <span className="icon alt-size fs-xl fw-bold">
                          <i>
                            <CiSearch />
                          </i>
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  className="related-blog-card-wrapper d-grid gap-lg-6 gap-4 p-xxl-8 p-lg-6 p-4 rounded bgc-2"
                  data-aos="zoom-in"
                >
                  <h4 className="fw-semibold">More Related Blog</h4>
                  <span className="d-block border-dashed"></span>
                  <div className="related-blog-card d-grid gap-lg-6 gap-4">
                  <div
                      
                      className="blog-card-item d-flex align-items-center gap-4"
                    >
                      <div className="img-area">
                        <img
                          className="w-100 rounded"
                          src={blog1}
                          alt="blog"
                        />
                      </div>
                      <div className="content-area">
                        <h6
                          className="fw-normal mb-2 char-limit"
                          data-maxlength="70"
                        >
                          React Native App Development Cost In India 2024: What You Need To Know
                        </h6>
                      
                      </div>
                    </div>
                    <div
                      
                      className="blog-card-item d-flex align-items-center gap-4"
                    >
                      <div className="img-area">
                        <img
                          className="w-100 rounded"
                          src={blog2}
                          alt="blog"
                        />
                      </div>
                      <div className="content-area">
                        <h6
                          className="fw-normal mb-2 char-limit"
                          data-maxlength="70"
                        >
                         How to Do Keyword Research for SEO Growth
                        </h6>
                       
                      </div>
                    </div>
                    <div
                      
                      className="blog-card-item d-flex align-items-center gap-4"
                    >
                      <div className="img-area">
                        <img
                          className="w-100 rounded"
                          src={blog3}
                          alt="blog"
                        />
                      </div>
                      <div className="content-area">
                        <h6
                          className="fw-normal mb-2 char-limit"
                          data-maxlength="70"
                        >
                         Mobile App Development Cost In India – A Detailed Guide
                        </h6>
                      
                      </div>
                    </div>
                    <div
                      
                      className="blog-card-item d-flex align-items-center gap-4"
                    >
                      <div className="img-area">
                        <img
                          className="w-100 rounded"
                          src={blog4}
                          alt="blog"
                        />
                      </div>
                      <div className="content-area">
                        <h6
                          className="fw-normal mb-2 char-limit"
                          data-maxlength="70"
                        >
                          5 Ways To Successfully Outsource Software Projects In India
                        </h6>
                      
                      </div>
                    </div>
                    <div
                      
                      className="blog-card-item d-flex align-items-center gap-4"
                    >
                      <div className="img-area">
                        <img
                          className="w-100 rounded"
                          src={blog5}
                          alt="blog"
                        />
                      </div>
                      <div className="content-area">
                        <h6
                          className="fw-normal mb-2 char-limit"
                          data-maxlength="70"
                        >
                         Staff Augmentation VS Outsourcing: Which Is Better Unlimited Guide
                        </h6>
                       
                      </div>
                    </div>
                  </div>
                  <span className="d-block border-dashed"></span>
                  <span>
                    <Link to="/Blog" className="link-btn">
                      See More
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Blogdetails;
