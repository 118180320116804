import React from "react";
import "./Footer.css"; // Assuming you will create a separate CSS file for styles
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FB from "../../assets/images/FB.png";
import twitter from "../../assets/images/twitter.png";
import linkedIn from "../../assets/images/linkedIn.png";
import insta from "../../assets/images/insta.png";
import google from "../../assets/images/google.png";
import logo from "../../assets/images/logo.png";
import shape from "../../assets/images/shape.png";
import { Link } from "react-router-dom";
import { BiPhoneCall } from "react-icons/bi";
import { FiMail } from "react-icons/fi";
import { BiMap } from "react-icons/bi";
import { CiLocationArrow1 } from "react-icons/ci";
import { NavLink } from "react-bootstrap";
import ScrollToTop from "react-scroll-to-top";
const Footer = () => {
  return (
    <>
      <section
        className="news-letter-section-02 texture-bg pb-120"
        id="newsletter-02"
      >
        <div className="top-wave-shape mt-n1">
          <img className="w-100" src={shape} alt="wave shape" />
        </div>
        <div className="container">
          <div className="row g-sm-6 g-2 pt-120">
            <div className="col-lg-8">
              <h3 className="display-four">
                "Stay Updated with Our Newsletter"
              </h3>
            </div>
            <div className="col-lg-4">
              <div className="input-area alt">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Type your email"
                />
                <button className="icon-btn">
                  <span className="icon alt-size fs-xl fw-bold">
                    <i>
                      <CiLocationArrow1 />
                    </i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer-section texture-bg">
        <Container>
          {/* Footer menu */}
          <Row  data-aos="fade-up" data-aos-duration="2000">
            <Col lg={3} sm={6}>
              <div className="footer-logo mb-lg-6 mb-4">
                <NavLink to="/" className="logo">
                  <img src={logo} alt="logo" />
                </NavLink>
              </div>
              <p className="description mb-lg-8 mb-sm-6 mb-4">
                IMPETROSYS, established over a decade ago, aims for global IT
                leadership by delivering high-quality custom web and mobile
                solutions for businesses across all industries.
              </p>
            </Col>
            <Col lg={3} sm={6}>
              <div className="footer-widget mt-5">
                <h4 className="widget-title">Quick Links</h4>
                <ul className="footer-menu d-grid gap-3">
                  <li>
                    <Link to="/about">About IMPETROSYS</Link>
                  </li>
                  <li>
                    <Link to="/career">Carrer @IMPETROSYS</Link>
                  </li>
                  <li>
                    <Link to="/contact">Get in Touch</Link>
                  </li>
                  <li>
                    <Link to="/blog">Latest Blogs</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} sm={6}>
              <div className="footer-widget mt-5">
                <h4 className="widget-title">Contact Information</h4>
                <ul className="footer-menu d-grid gap-3">
                  <li>
                    <a
                      href="tel:+91 731 358 9090"
                      className="d-flex align-items-center gap-3 alt"
                    >
                      <i>
                        <BiPhoneCall />
                      </i>{" "}
                      +91 731 358 9090
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:info@example@gmail.com"
                      className="d-flex align-items-center gap-3 alt"
                    >
                      <i>
                        <FiMail />
                      </i>
                      info@impetrosys.com
                    </a>
                  </li>
                  <li>
                    <a className="d-flex align-items-center gap-3 alt">
                      <i>
                        <BiMap />
                      </i>
                      403 Fortune Ambience, South Tukoganj, Indore 452001
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} sm={6}>
              <div className="footer-widget mt-5">
                <h4 className="widget-title">Follow us</h4>
                <p className="mb-lg-6 mb-4">
                  Don’t miss out—follow us today and stay updated with the
                  latest excitement directly in your feed!
                </p>
                <ul className="d-flex align-items-center justify-content-center justify-content-lg-start gap-sm-4 gap-2">
                  <li>
                    <a href="https://www.facebook.com/ImpetrosysSoftwareSolution" target="_blank" rel="noopener noreferrer">
                      <img
                        src={FB}
                        alt="facebook icon"
                        className="footer-img"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://x.com/IMPETROSYS_IND"target="_blank" rel="noopener noreferrer">
                      <img
                        src={twitter}
                        alt="twitter icon"
                        className="footer-img"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/impetrosys"target="_blank" rel="noopener noreferrer">
                      <img
                        src={linkedIn}
                        alt="linkedin icon"
                        className="footer-img"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/_impetrosys"target="_blank" rel="noopener noreferrer">
                      <img
                        src={insta}
                        alt="instagram icon"
                        className="footer-img"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://g.co/kgs/89gVGro"target="_blank" rel="noopener noreferrer">
                      <img
                        src={google}
                        alt="google icon"
                        className="footer-img"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          {/* Footer bottom */}
          <div className="footer-bottom d-between flex-wrap-reverse ">
            <span>
              Copyright © {new Date().getFullYear()}{" "}
              <a href="#" className="tcp-1 link-text-2">
                IMPETROSYS
              </a>{" "}
              All Rights Reserved.
            </span>
            <div className="footer-widget">
              <ul className="footer-menu d-flex gap-4">
                <li>
                  <Link to="/terms-and-condition">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/faq">FAQs</Link>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </footer>
      <ScrollToTop
        smooth
        color="#000"
        style={{ background: "#1ee579", bottom: "10px", right: "8px" }}
      />
    </>
  );
};

export default Footer;
