import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import CustomSelect from "./CustomSelect";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Assuming you're using AOS for animations
import bottomshape from "../../assets/images/bottomshape.png";
import signup from "../../assets/images/signup.png";
import { FaArrowRightLong } from "react-icons/fa6";
import { Col, Row, Form } from "react-bootstrap";
import Footer from "../../Comman/Footers/Footer";
import Navbar from "../../Comman/Header/Navbar";
import { Link } from "react-router-dom";

const SignUpSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  // Formik form handler
  const formik = useFormik({
    initialValues: {
      fname: "",
      email: "",
      phone: "",
      experienceLevel: "",
      projectType: "",
      timeline: "",
      description: "",
    },
    onSubmit: (values) => {
      console.log("Form Submitted", values);
      // Handle form submission (e.g., send data to backend)
    },
  });

  // Phone number state
  const [mobilenumber, setmobilenumber] = useState("");

  // Tag input state management
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");

  // Handle tag input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Handle adding a new tag
  const handleAddTag = (e) => {
    if (e.key === "Enter" && inputValue) {
      setTags([...tags, inputValue]);
      setInputValue(""); // Clear input after adding
    }
  };

  // Handle removing a tag
  const removeTag = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const options2 = [
    { value: "06", label: "0-6 Month" },
    { value: "01", label: "6 Month - 1 Year" },
    { value: "03", label: "1 Year - 2 Year" },
    { value: "04", label: "2 Year - 3 Year" },
    { value: "05", label: "3+ Year" },
  ];
  const options3 = [
    { value: "Consultation", label: "Consultation" },
    { value: "full-time", label: "Full-time" },
    { value: "part-time", label: "Part-time" },
    { value: "freelance", label: "Freelance" },
    { value: "etc", label: "etc" },
  ];
  const options4 = [
    { value: "1 Month", label: "1 Month" },
    { value: "2 Month", label: "1 Month - 2 Months" },
    { value: "3 Month", label: "2 Months - 3 Months" },
    { value: "4 Month", label: "3 Months - 6 Months" },
    { value: "6", label: "More than 6 Months" },
  ];

  return (
    <>
      <Navbar />
      <section className="sign-up auth-container inner-hero-section overflow-hidden texture-bg-2 pt-120">
        <div className="container pt-120">
          <div className="row g-6 justify-content-between">
            <div className="col-lg-6">
              <div className="sign-up-form" data-aos="fade-up">
                <Form className="auth-form p-xl-8 p-4 bgc-2 rounded" onSubmit={formik.handleSubmit}>
                  <div className="form-content">
                    <h3 className="mb-1">Let’s Get Started!</h3>
                    <p className="mb-3">
                      Please submit the details about your requirement
                    </p>
                  </div>
                  <Row>
                    <Col md={6}>
                    <div className="expert-siup">
                      <Form.Group>
                        <label htmlFor="fname" className="mb-2">Name</label>
                        <Form.Control
                        placeholder="Enter Your Name"
                          type="text"
                          id="fname"
                          name="fname"
                          value={formik.values.fname}
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                      </div>
                    </Col>
                    <Col md={6}>
                    <div className="expert-siup">
                      <Form.Group>
                   
                        <label htmlFor="email"className="mb-2">Email</label>
                        <Form.Control
                        placeholder="Enter Your Email"
                          type="email"
                          id="email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                      </div>
                    </Col>
                    <Col md={6}>
                    <div className="expert-siup">
                      <Form.Group>
                        <label htmlFor="phone"className="mb-2">Phone Number</label>
                        <PhoneInput
                          country={"in"}
                          value={mobilenumber}
                          onChange={(phone) => {
                            setmobilenumber(phone);
                            formik.setFieldValue("phone", phone);
                          }}
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: false,
                            style: { marginLeft: "0px", width: "100%" },
                          }}
                        />
                      </Form.Group>
                      </div>
                    </Col>
                    <Col md={6}>
                    <div className="expert-siup">
                      <Form.Group>
                        <label htmlFor="experienceLevel"className="mb-2">Experience Level</label>
                        <CustomSelect
                          options={options2}
                          placeholder="Experience Level"
                          value={formik.values.experienceLevel}
                          onChange={(option) => formik.setFieldValue("experienceLevel", option.value)}
                        />
                      </Form.Group>
                      </div>
                    </Col>
                    <Col md={12}>
                    <div className="expert-siup">
                      <Form.Group>
                        <label htmlFor="expertiseArea"className="mb-2">Expertise Area</label>
                        <div className="tags-input-container">
                          <ul className="tags-list">
                            {tags.map((tag, index) => (
                              <li key={index} className="tag-item">
                                {tag}
                                <span
                                  className="remove-tag"
                                  onClick={() => removeTag(index)}
                                >
                                  &times;
                                </span>
                              </li>
                            ))}
                          </ul>
                          <input
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={handleAddTag}
                            className="form-control-1"
                            placeholder="Expertise Tag"
                          />
                        </div>
                      </Form.Group>
                      </div>
                    </Col>
                    <Col md={6}>
                    <div className="expert-siup">
                      <Form.Group>
                        <label htmlFor="projectType"className="mb-2">Project Type</label>
                        <CustomSelect
                          options={options3}
                          placeholder=" Project Type"
                          value={formik.values.projectType}
                          onChange={(option) => formik.setFieldValue("projectType", option.value)}
                        />
                      </Form.Group>
                      </div>
                    </Col>
                    <Col md={6}>
                    <div className="expert-siup">
                      <Form.Group>
                        <label htmlFor="timeline"className="mb-2">Timeline</label>
                        <CustomSelect
                          placeholder="Timeline"
                          options={options4}
                          value={formik.values.timeline}
                          onChange={(option) => formik.setFieldValue("timeline", option.value)}
                        />
                      </Form.Group>
                      </div>
                    </Col>
                    <Col md={12}>
              <div className="rqfrom-us-new">
                <div className="field file-upload">
                  <label htmlFor="request" className="Request_field">
                    Upload Your Requirement
                  </label>
                  <input
                    type="file"
                    className="form-control custom-file-input"
                    id="uploadfile"
                    // ref={fileInputRef}
                    // onChange={(e) => {
                    //   upload(e);
                    // }}
                  />
                   
                </div>
                </div>
              </Col>
                    <Col md={12}>
                      <div className="expert-siup">
                      <Form.Group>
                        <label htmlFor="description"className="mb-2">About Your Message</label>
                        <Form.Control
                        placeholder="Enter Your Message"
                          as="textarea"
                          name="description"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          style={{ height: "100px" }}
                        />
                      </Form.Group>
                      </div>
                    </Col>
                  </Row>
                  <p className="my-lg-8 my-sm-6 mb-3">
                    By signing up, you agree to our{" "}
                    <Link to="/terms-and-condition" className="tcp-1">
                      Terms and Conditions
                    </Link>
                    ,{" "}
                    <Link to="/privacy-policy" className="tcp-1">
                      Privacy Policy
                    </Link>
                    , E-sign & Communication Authorization
                  </p>
                  <button className="bttn-1" type="submit">
                    Sign Up
                    <span className="icon d-center icon-right">
                      <i>
                        <FaArrowRightLong />
                      </i>
                    </span>
                  </button>
                </Form>
              </div>
            </div>
            <div className="col-xxl-5 col-lg-6">
              <div className="auth-banner mt-5" data-aos="fade-down">
                <img className="w-100" src={signup} alt="sign up" />
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-wave-shape">
          <img className="w-100" src={bottomshape} alt="wave shape" />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SignUpSection;
