
import { useEffect, useState } from 'react'
import  Routes  from "./Routes"
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import GoToTop from "../src/GoToTop";
const App = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <>
      <Routes/>
      <GoToTop /> 
    </>
  );
};

export default App;