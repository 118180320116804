import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./Contact.css";
import { BsRocketTakeoff } from "react-icons/bs";
import topwave from "../../assets/images/topwave.png";
import Navbar from "../../Comman/Header/Navbar";
import { Container, Row, Col, NavLink, Form } from "react-bootstrap";
import { FaArrowRightLong } from "react-icons/fa6";
import shape from "../../assets/images/shape.png";
import { TbMapPinCog } from "react-icons/tb";
import { IoCallOutline } from "react-icons/io5";
import Footer from "../../Comman/Footers/Footer";

const ContactUs = () => {
  // mobile number state
  const [mobilenumber, setmobilenumber] = useState("");
  const [valid, setvalid] = useState(false);
  const [mobilevalid, setmobilevalid] = useState(false);
  const [mobilevalidationerror, setmobilevalidationerror] = useState("");

  const mobilevalidation = (mobile) => {
    if (!new RegExp(/^[0-9+].{6,12}$/).test(mobile)) {
      setmobilevalidationerror("Please Enter A valid Mobile Number");
      setmobilevalid(false);
    } else {
      setmobilevalidationerror("");
      setmobilevalid(true);
    }
  };

  const showToastMessageone = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showAlert = (message) => {
    Swal.fire({
      title: "Message Sent",
      text: message,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  function handleSubmit(values) {
    const bodyFormData = new FormData();
    bodyFormData.append("name", values.name);
    bodyFormData.append("email", values.email);
    bodyFormData.append("subject", values.subject);
    bodyFormData.append("contact", mobilenumber);
    bodyFormData.append("message", values.message);
    const url = `${process.env.REACT_APP_BASE_URL}/sendcontacts`;
    axios
      .post(url, bodyFormData, {
        "Content-Type": "multipart/form-data",
        headers: { Accept: "application/json" },
      })
      .then((response) => {
        if (response.data.status_code === 200) {
          showAlert(response.data.message);
          setmobilenumber("");
        } else if (response.data.status_code === 201) {
          showToastMessageone(response.data.message.name);
          showToastMessageone(response.data.message.email);
          showToastMessageone(response.data.message.subject);
          showToastMessageone(response.data.message.contact);
          showToastMessageone(response.data.message.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      contact: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),

      email: Yup.string()
        .email("Invalid email format")
        .matches(/^\S*$/, "Empty Whitespace Not Allowed")
        .required("Required!"),

      subject: Yup.string().required("Required!"),
      message: Yup.string().required("Required!"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
      formik.resetForm();
    },
  });
  return (
    <>
      <Navbar />
      <section className="contact-us-form-section texture-bg pt-120">
        <div className="top-wave-shape mt-n1">
          <img className="w-100" src={shape} alt="wave shape" />
        </div>

        <Container>
          <Row className="g-6 justify-content-between align-items-center">
            <Col lg={6} md={6}>
              <div className="contact-us-content-wrapper" data-aos="fade-up">
                <div className="content-area text-center text-lg-start mb-lg-10 mb-sm-6 mb-4">
                  <span className="subheading-border fw-medium mb-4 fs-xl">
                    <span className="fs-2xl">
                      <i>
                        <BsRocketTakeoff />
                      </i>
                    </span>
                    Contact Us
                  </span>
                  <h4
                    className="display-four mb-4"
                    data-aos="zoom-in"
                  >
                    Have questions? Don’t hesitate to contact us
                  </h4>
                  <p className="description fw-normal mb-4">
                    The purpose of an introduction is to grab the audience's
                    attention, provide a preview of the value or insights.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div
                className="contact-us-form-wrapper p-xl-6 p-lg-4 p-3 rounded bgc-3"
                data-aos="fade-down"
              >
                <div className="contact-card-wrapper p-xl-6 p-4 rounded bgc-2">
                  <h4 className="fw-semibold mb-4">Get In Touch</h4>
                  <Form
                    onSubmit={formik.handleSubmit}
                    className="border-dashed pt-4"
                  >
                    <Row>
                      <Col md={12}>
                        <div className="input-wrapper d-grid gap-3 mb-4">
                          <label htmlFor="name">Name</label>
                          <Form.Group>
                            <Form.Control
                              placeholder="Enter Your Name..."
                              type="text"
                              name="name"
                              autoComplete="off"
                              value={formik.values.name}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.name && formik.touched.name && (
                              <p className="errormessage">
                                {formik.errors.name}
                              </p>
                            )}
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="input-wrapper d-grid gap-3 mb-4">
                          <label htmlFor="email"> Email</label>
                          <Form.Group>
                            <Form.Control
                              placeholder="Enter Your Email..."
                              type="email"
                              name="email"
                              autoComplete="off"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.email && formik.touched.email && (
                              <p className="errormessage">
                                {formik.errors.email}
                              </p>
                            )}
                          </Form.Group>
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="input-wrapper d-grid gap-3 mb-2">
                          <label htmlFor="mobile">Phone</label>
                          <PhoneInput
                            country={"in"}
                            value={mobilenumber}
                            onChange={(phone, value) => {
                              setmobilenumber(phone);
                              mobilevalidation(phone);
                            }}
                            prefix="+"
                            inputProps={{
                              name: "phone",
                              required: true,
                              autoFocus: true,
                              autoComplete: "off",
                            }}
                            isValid={(validmobilenumber) => {
                              if (
                                !new RegExp(/^[0-9+].{6,12}$/).test(
                                  validmobilenumber
                                )
                              ) {
                                setvalid(false);

                                return false;
                              } else {
                                setvalid(true);

                                return true;
                              }
                            }}
                          />
                          <span>
                            {mobilevalid ? (
                              <></>
                            ) : (
                              <>
                                <p className="mobile-error">
                                  {mobilevalidationerror}
                                </p>
                              </>
                            )}
                          </span>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="input-wrapper d-grid gap-3 mb-4">
                          <label htmlFor="subject">Subject</label>
                          <Form.Group>
                            <Form.Control
                              placeholder="Enter Your Subject..."
                              type="text"
                              name="subject"
                              autoComplete="off"
                              value={formik.values.subject}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.subject &&
                              formik.touched.subject && (
                                <p className="errormessage">
                                  {formik.errors.subject}
                                </p>
                              )}
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="input-wrapper d-grid gap-3 mb-2">
                          <label htmlFor="message">Message </label>
                          <Form.Control
                            placeholder="Enter Your Message..."
                            as="textarea"
                            style={{ height: "108px" }}
                            type="message"
                            name="message"
                            autoComplete="off"
                            rows={4}
                            value={formik.values.message}
                            onChange={formik.handleChange}
                          />
                          {formik.errors.message && formik.touched.message && (
                            <p className="errormessage">
                              {formik.errors.message}
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <button
                          type="submit"
                          className="bttn-1 mt-lg-10 mt-sm-6 mt-4"
                        >
                          Send Message
                          <span className="icon d-center icon-right">
                            <i>
                              <FaArrowRightLong />
                            </i>
                          </span>
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="office-address-section pt-120 pb-0 texture-bg-2">
        <Container>
          <Row className="justify-content-center  mb-4" data-aos="zoom-in">
            <Col lg={6} className="text-center">
              <span className="subheading-border fw-medium mb-4 fs-xl">
                <span className="fs-2xl">
                  <i>
                    <BsRocketTakeoff />
                  </i>
                </span>
                Get In Touch
              </span>
              <h4 className="display-four">Let us help you</h4>
            </Col>
          </Row>
          <Row className="g-6">
            <Col lg={4} md={4} data-aos="zoom-in-up">
              <div className="office-address-card d-flex gap-lg-6 gap-sm-4 gap-2 py-xxl-10 py-lg-8 py-sm-6 py-4 px-xxl-8 px-sm-6 px-4 rounded">
                <span className="fs-two fw-normal icon">
                  <i>
                    <TbMapPinCog />
                  </i>
                </span>
                <div className="content">
                  <h3 className="contac-title fw-bold mb-lg-3 mb-2">
                    Development Center
                  </h3>
                  <p className="description mb-4">
                    403 Fortune Ambience, South Tukoganj, Indore 452001 India
                  </p>
                  <a
                    href="https://www.google.com/maps/dir/22.7174135,75.8781628/403+Fortune+Ambience+-+Business+Space,+South+Tukoganj,+Indore,+Madhya+Pradesh+452001/@22.7184784,75.8758108,16.69z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3962fd13e26d019b:0x69548871791255d1!2m2!1d75.877137!2d22.7172763?entry=ttu&amp;g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D"
                    target="_blank"
                    className="link-btn fw-semibold text-nowrap"
                  >
                    Find Location
                  </a>
                </div>
              </div>
            </Col>

            <Col lg={4} md={4} data-aos="zoom-in-up">
              <div className="office-address-card d-flex gap-lg-6 gap-sm-4 gap-2 py-xxl-10 py-lg-8 py-sm-6 py-4 px-xxl-8 px-sm-6 px-4 rounded">
                <span className="fs-two fw-normal icon">
                  <i>
                    <TbMapPinCog />
                  </i>
                </span>
                <div className="content">
                  <h3 className="contac-title fw-bold mb-lg-3 mb-2">
                    Business Center
                  </h3>
                  <p className="description mb-4">
                    244 5th Avenue, Suite B-205, New York, NY, 10001, USA
                  </p>
                  <a
                    href="https://www.google.com/maps/place/250+5th+Ave,+New+York,+NY+10001,+USA/@40.744619,-73.98767,17z/data=!4m15!1m8!3m7!1s0x89c259a66b36eb93:0x8438a377b6e80925!2s244+5th+Ave+suite+b+205,+New+York,+NY+10001,+USA!3b1!8m2!3d40.744619!4d-73.98767!16s%2Fg%2F11n09bm6rn!3m5!1s0x89c259a615d8e9ef:0xb942310b02ef7239!8m2!3d40.744913!4d-73.9875253!16s%2Fg%2F11bw3xnmzm?entry=ttu&amp;g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D"
                    target="_blank"
                    className="link-btn fw-semibold text-nowrap"
                  >
                    Find Location
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} data-aos="zoom-in-down">
              <div className="office-address-card d-flex gap-lg-6 gap-sm-4 gap-2 py-xxl-10 py-lg-8 py-sm-6 py-4 px-xxl-8 px-sm-6 px-4 rounded">
                <span className="fs-two fw-normal icon">
                  <i>
                    <IoCallOutline />
                  </i>
                </span>
                <div className="content">
                  <h3 className="contac-title fw-bold mb-lg-3 mb-2">
                    Contact Information
                  </h3>
                  <div className="phone-address d-grid gap-1 mb-4">
                    <a
                      href="mailto:info@impetrosys.com"
                      className="description"
                    >
                      info@impetrosys.com
                    </a>
                    <a
                      href="mailto:business@impetrosys.com"
                      className="description"
                    >
                      business@impetrosys.com
                    </a>
                  </div>
                  <a href="/#" className="link-btn fw-semibold text-nowrap">
                    Contact Us Today!
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default ContactUs;
