import React,{useEffect} from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import 'swiper/css/autoplay'; 
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation, FreeMode ,Autoplay } from 'swiper/modules';
import "./Lifecycle.css";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Container, Row, Col } from "react-bootstrap";
import { PiLightbulbFilamentThin } from "react-icons/pi";
import { LiaClipboardListSolid } from "react-icons/lia";
import { LiaLaptopCodeSolid } from "react-icons/lia";
import { FaConnectdevelop } from "react-icons/fa6";

const FeaturedSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section className="featured-section pt-120 pb-120 texture-bg-2">
      <Container>
        <Row className="aos-init aos-animate mb-5" data-aos="flip-left">
          <Col lg={8}  className="feater">
            <div className="text-center text-lg-start">
              <h4 className="hero-title display-four">
                How We Manage the Life Cycle at IMPETROSYS
              </h4>
            </div>
          </Col>
          <Col lg={4} >
            <div className="lyfcle-bt d-none d-lg-block">
              <div className="mt-4 swiper-btns swiper-top-btn d-flex align-items-center  gap-3">
                <div
                  className="features-prev button-prev fs-lg"
                  tabIndex="0"
                  role="button"
                  aria-label="Previous slide"
                >
                  <i>
                    <MdKeyboardArrowLeft />
                  </i>
                </div>
                <div
                  className="features-next button-next fs-lg"
                  tabIndex="0"
                  role="button"
                  aria-label="Next slide"
                >
                  <i>
                    <MdKeyboardArrowRight />
                  </i>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="sw" data-aos="fade-right" data-aos-duration="2000">
        <Swiper
        
          modules={[Navigation, FreeMode,Autoplay]}
          // className="features-swiper"
          navigation={{
            nextEl: ".features-next",
            prevEl: ".features-prev",
          }}
          autoplay={{
            delay: 3000, // Slide delay in ms (3 seconds)
            disableOnInteraction: false, // Autoplay continues after user interaction
        }}
          freeMode={true}
          slidesPerView={3}
          spaceBetween={30}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 40,
            },
            991: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
          }}
        >
          <SwiperSlide >
            <div className="features-card lefecycle text-center"
            >
              <div className="card-icon mb-lg-8 mb-sm-6 mb-4">
                <span className="fs-one fw-normal tcp-1">
                  <i>
                    <LiaClipboardListSolid />
                  </i>
                </span>
              </div>
              <h3 className="lifecycle-text mb-lg-6 mb-sm-4 mb-2">
                Strategic Planning
              </h3>
              <p className="lifecycle-paragraph">
                Defining project goals, scope, and requirements to set a clear
                direction.
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="features-card lefecycle text-center" >
              <div className="card-icon mb-lg-8 mb-sm-6 mb-4">
                <span className="fs-one fw-normal tcp-1">
                  <i>
                    <FaConnectdevelop />
                  </i>
                </span>
              </div>
              <h3 className="lifecycle-text mb-lg-6 mb-sm-4 mb-2">
                Efficient Development
              </h3>
              <p className="lifecycle-paragraph">
                Building robust, scalable solutions using the latest
                technologies and best practices.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="features-card lefecycle text-center" >
              <div className="card-icon mb-lg-8 mb-sm-6 mb-4">
                <span className="fs-one fw-normal tcp-1">
                  <i>
                    <LiaLaptopCodeSolid />
                  </i>
                </span>
              </div>
              <h3 className="lifecycle-text mb-lg-6 mb-sm-4 mb-2">
                Rigorous Testing & Deployment
              </h3>
              <p className="lifecycle-paragraph">
                Delivering optimal performance through seamless deployment and
                rigorous quality testing.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="features-card lefecycle text-center" >
              <div className="card-icon mb-lg-8 mb-sm-6 mb-4">
                <span className="fs-one fw-normal tcp-1">
                  <i>
                    <PiLightbulbFilamentThin />
                  </i>
                </span>
              </div>
              <h3 className="lifecycle-text mb-lg-6 mb-sm-4 mb-2">
                Innovative & Trending Design
              </h3>
              <p className="lifecycle-paragraph">
                Crafting user-centric designs that balance functionality with
                aesthetics.
              </p>
            </div>
          </SwiperSlide>
          <div className="lyfcle-bt d-lg-none">
              <div className="mt-4 swiper-btns swiper-top-btn d-flex align-items-center  gap-3">
                <div
                  className="features-prev button-prev fs-lg"
                  tabIndex="0"
                  role="button"
                  aria-label="Previous slide"
                >
                  <i>
                    <MdKeyboardArrowLeft />
                  </i>
                </div>
                <div
                  className="features-next button-next fs-lg"
                  tabIndex="0"
                  role="button"
                  aria-label="Next slide"
                >
                  <i>
                    <MdKeyboardArrowRight />
                  </i>
                </div>
              </div>
            </div>
        </Swiper>
        </div>
      </Container>
    </section>
  );
};

export default FeaturedSection;
