import React from 'react';
import "./Lifecycle.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, FreeMode ,Autoplay } from 'swiper/modules';
import 'swiper/css/autoplay'; 
import { BsRocketTakeoff } from "react-icons/bs";
import { MdKeyboardArrowLeft,MdKeyboardArrowRight } from "react-icons/md";
import { Container,Row,Col } from 'react-bootstrap';
import bottomshape from "../../../assets/images/bottomshape.png";
import team1 from "../../../assets/images/team/team1.png";
import team2 from "../../../assets/images/team/team2.png";
import team3 from "../../../assets/images/team/team3.png";
import team4 from "../../../assets/images/team/team4.png";
import team5 from "../../../assets/images/team/team5.png";
import team6 from "../../../assets/images/team/team6.png";


const ExpertsProfile = () => {
  return (
    <section className="host-profile-section texture-bg">
      <Container className="pt-120 pb-120">
        <Row className="row align-items-center mb-lg-15 mb-sm-10 mb-8 aos-init aos-animate mb-5" data-aos="flip-left">
          <Col lg={8}>
            <div className="text-center text-lg-start">
              <span className="subheading-border fw-semibold mb-4 fs-xl">
                <span className="fs-2xl">
                  <i><BsRocketTakeoff/></i>
                </span>
                Experts Profiles
              </span>
              <h4 className="display-four fw-semibold">Meet the Experts Behind our Success</h4>
            </div>
          </Col>
          <Col lg={4}>
          <div className=' d-none d-lg-block'>
          <div className="swiper-btns swiper-top-btn d-flex align-items-center justify-content-end gap-4">
            
          <div className="features-prev button-prev fs-lg" tabIndex="0" role="button" aria-label="Previous slide">
                <i><MdKeyboardArrowLeft/></i>
              </div>
              <div className="features-next button-next fs-lg" tabIndex="0" role="button" aria-label="Next slide">
                <i><MdKeyboardArrowRight/></i>
              </div>
            </div>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="col-12 mt-5 aos-init aos-animate" data-aos="fade-up">
            <Swiper
              modules={[Navigation, FreeMode ,Autoplay]}
              className="features-swiper"
              navigation={{
                nextEl: '.features-next',
                prevEl: '.features-prev',
              }}
              freeMode={true}
              slidesPerView={4}
              spaceBetween={30}

              autoplay={{
                delay: 3000, // Slide delay in ms (3 seconds)
                disableOnInteraction: false, // Autoplay continues after user interaction
            }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                480: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                991: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
              }}
            >
              <SwiperSlide>
                <div className="host-card">
                  <div className="host-profile position-relative">
                    <div className="img-area overflow-hidden">
                      <img className="w-100 rounded" src={team3} alt="profile" />
                    </div>
                   
                  </div>
                  <div className="host-info">
                    <h4 className="fw-semibold mb-2">
                    Mr. Suraj Pandagre
                    </h4>
                    <span className="fs-sm">Sr.Project Manager</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="host-card mt-5">
                  <div className="host-profile position-relative">
                    <div className="img-area overflow-hidden">
                      <img className="w-100 rounded" src={team4} alt="profile" />
                    </div>
                    
                  </div>
                  <div className="host-info">
                    <h4 className="fw-semibold mb-2">
                    Miss. Shailesh Bhadoriya
                    </h4>
                    <span className="fs-sm"> Sr. IOS developer</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="host-card">
                  <div className="host-profile position-relative">
                    <div className="img-area overflow-hidden">
                      <img className="w-100 rounded" src={team2} alt="profile" />
                    </div>
                    
                  </div>
                  <div className="host-info">
                    <h4 className="fw-semibold mb-2">
                    Mr. Kamlesh Rajpoot
                    </h4>
                    <span className="fs-sm"> Sr. FullStack developer</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="host-card mt-5">
                  <div className="host-profile position-relative ">
                    <div className="img-area overflow-hidden">
                      <img className="w-100 rounded" src={team1} alt="profile" />
                    </div>
                    
                  </div>
                  <div className="host-info">
                    <h4 className="fw-semibold mb-2">
                    Miss. Dipali Jain
                    </h4>
                    <span className="fs-sm">  Sr. Web designer</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="host-card">
                  <div className="host-profile position-relative">
                    <div className="img-area overflow-hidden">
                      <img className="w-100 rounded" src={team5} alt="profile" />
                    </div>
                    
                  </div>
                  <div className="host-info">
                    <h4 className="fw-semibold mb-2">
                    Mr. Raj Kumar Patel
                    </h4>
                    <span className="fs-sm"> Sr.Flutter Developer</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="host-card mt-5">
                  <div className="host-profile position-relative">
                    <div className="img-area overflow-hidden">
                      <img className="w-100 rounded" src={team6} alt="profile" />
                    </div>
                    
                  </div>
                  <div className="host-info">
                    <h4 className="fw-semibold mb-2">
                    Miss.Rashmi Lodhi
                    </h4>
                    <span className="fs-sm"> Sr.Frontend Developer</span>
                  </div>
                </div>
              </SwiperSlide>
              <div className=' d-flex d-lg-none align-items-center justify-content-center'>
          <div className="mt-4 swiper-btns swiper-top-btn d-flex align-items-center  gap-3">
            
          <div className="features-prev button-prev fs-lg" tabIndex="0" role="button" aria-label="Previous slide">
                <i><MdKeyboardArrowLeft/></i>
              </div>
              <div className="features-next button-next fs-lg" tabIndex="0" role="button" aria-label="Next slide">
                <i><MdKeyboardArrowRight/></i>
              </div>
            </div>
            </div>
            </Swiper>
            
          </div>
        </Row>
      </Container>
      <div className="bottom-wave-shape">
        <img className="w-100" src={bottomshape} alt="wave shape" />
      </div>
    </section>
  );
};

export default ExpertsProfile;
